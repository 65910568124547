import { removeImageExtensionFromName } from '../_shared/heplers/hepler-functions';
import { updateOrderItems } from '../store/order/order.actions';
import { FormGroup } from '@angular/forms';
import { OrderItem } from '../_shared/models/order-item';
import { Order } from '../_shared/models/order';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { OrderItemService } from '../services/api/order-item/order-item.service';
import { ToastController } from '@ionic/angular';
import { ImagePickerEvent } from '../_shared/models/ImagePickerEvent';

export default class SinglePhotoItem {
  public orderForm: FormGroup;
  public orderItem: OrderItem;
  public order: Order;
  public formReady            = false;
  public step                 = 1;
  public numberOfPhotos       = 1;
  public imageLoadingArray: number[]    = [];
  public numberOfImagesFailed = 0;

  constructor(
    public store: Store<State>,
    public orderItemService: OrderItemService,
    public toastController: ToastController
  ) {
  }

  async onImagePick(event: ImagePickerEvent) {
    this.numberOfImagesFailed = 0;
    for (let i = 0; i < event.files?.length; i++) {
      try {
        const request_data = new FormData();
        request_data.append('order_id', this.order.uuid);
        request_data.append('item_id', this.orderItem.id.toString());
        let fileName: string;
        if (event.fromPhone) {
          fileName = removeImageExtensionFromName(event.files?.item(i)?.name);
          request_data.append('photo', event.files[i], fileName);
        } else {
          fileName = removeImageExtensionFromName(event.files?.item(i)?.name);
          request_data.append('photo', event.files?.item(i), fileName);
        }

        this.imageLoadingArray.push(i);
        const res = await this.orderItemService.uploadImage(request_data).toPromise();
        this.imageLoadingArray.pop();
        const updatedPhotosList = [...this.orderItem.photos, res];

        this.orderItem = {
          ...this.orderItem,
          photos        : updatedPhotosList,
        };
      } catch (e) {
        console.log(e);
        this.numberOfImagesFailed++;
        this.imageLoadingArray.pop();
      }
    }

    if (this.numberOfImagesFailed === 0) {
      this.updateOrder();

      const toast = await this.toastController.create({
        message : 'Sve slike su uspešno uvežene.',
        duration: 2000,
        color   : 'success',
      });
      await toast.present();
    }
  }

  onImageRemove(removedImageId: number | string) {
    this.orderItem.photos = this.orderItem.photos.filter(img => img.id !== removedImageId);
    this.updateOrder();
  }

  updateOrder() {
    const updatedOrder = {...this.order, order_id: this.order.uuid};
    if (!updatedOrder.items.find(orderItem => orderItem.id === this.orderItem.id)) {
      if (this.numberOfPhotos > 1 && this.orderItem.complete) {
        updatedOrder.items = [
          ...updatedOrder.items,
          ...this.orderItem.photos.map(photo => ({...this.orderItem, id: null, quantity: 1, photos: [photo]}))
        ];
      } else {
        updatedOrder.items = [...updatedOrder.items, this.orderItem];
      }
    } else {
      if (this.numberOfPhotos > 1 && this.orderItem.complete) {
        const currentItem = updatedOrder.items.find(item => item.id === this.orderItem.id);
        updatedOrder.items = [
          ...updatedOrder.items.filter(item => item.id !== this.orderItem.id),
          {...currentItem, quantity: 1, photos: [this.orderItem.photos[0]]},
          ...this.orderItem.photos.slice(0).map(photo => ({...this.orderItem, id: null, quantity: 1, photos: [photo]}))
        ];
      } else {
        updatedOrder.items = updatedOrder.items.map(orderItem => orderItem.id === this.orderItem.id ? this.orderItem : orderItem);
      }
    }
    this.store.dispatch(updateOrderItems({data: updatedOrder, orderItemCompleted: this.orderItem.complete}));
  }

  quantityChange(value: number) {
    this.orderForm.patchValue({quantity: value});
    this.numberOfPhotos = value;
  }

  addToCart() {
    if (this.orderItem.photos.length !== this.numberOfPhotos) {
      this.toastController.create({
        message : 'Molimo vas da izaberete potreban broj slika',
        duration: 2000,
        color   : 'danger',
      })
        .then(toast => {
          void toast.present();
        });
      return;
    }
    this.orderItem.complete = true;
    this.updateOrder();
  }
}
